import { useSellerCoupons } from '@util/hooks/useSellerCoupons';
import { useShoppingCart } from 'context/ShoppingCartContext';
import { ProductDocument } from 'models/product';
import CouponIcon from './icons/assets/coupon.svg';
import Image from 'next/image';
import {
  formatCurrency,
  getCartItemFromProductDocument,
  isMobile,
} from '@util/index';
import { useAuth } from 'context/AuthContext';
import { usePathname, useRouter } from 'next/navigation';
import { useToastContext } from 'context/ToastContext';
import { useAttributionContext } from 'context/AttributionContext';

type ProductCouponDisplayProps = {
  product: ProductDocument;
};

export default function ProductCouponDisplay({
  product,
}: ProductCouponDisplayProps) {
  const { sellerCoupons } = useSellerCoupons({
    seller_id: product.seller_id,
    is_private: false,
    is_active: true,
  });
  const { addCoupons, setCartOpen, canAddProductToCart, increaseCartQty } =
    useShoppingCart();
  const { getAttribution } = useAttributionContext();
  const { showErrorToast } = useToastContext();
  const { userDoc, user } = useAuth();
  const router = useRouter();
  const pathname = usePathname();

  if (
    !sellerCoupons?.length ||
    !sellerCoupons.some((coupon) => coupon.active)
  ) {
    return null;
  }

  const activeCoupon = sellerCoupons.find(
    (coupon) => !coupon.product_id && coupon.active && !coupon.buyer_id
  );

  const handleAddToCart = () => {
    if (!userDoc) {
      router.push(`/login?redirect=${encodeURI(pathname ?? '')}`);
      return;
    }

    if (!canAddProductToCart(product.id)) {
      showErrorToast('Cannot add product to cart');

      setCartOpen(true);
      return;
    }

    const attribution = getAttribution(product.id);

    const cartItem = getCartItemFromProductDocument(product, [], attribution);

    increaseCartQty(cartItem);
  };

  const applyCoupon = () => {
    if (!activeCoupon) return;

    addCoupons([activeCoupon]);
    handleAddToCart();

    setCartOpen(true);
  };

  if (!activeCoupon) return null;

  return (
    <div className="flex items-center gap-x-4 rounded-[1.5rem] bg-[#F1F1F1] p-4">
      <Image
        src={CouponIcon}
        alt="Coupon"
        className="mt-2 h-10 w-10 sm:mt-0 sm:h-8 sm:w-8"
      />

      <div className="flex w-full items-center justify-between">
        <p className="text-[1.4rem] font-medium text-black sm:text-[1.6rem]">
          Take an extra{' '}
          {activeCoupon.percent_off ? (
            <span className="font-semibold text-brand-secondary">
              {activeCoupon.percent_off}% OFF
            </span>
          ) : (
            <>
              {activeCoupon.amount_off && (
                <span className="font-semibold text-brand-secondary">
                  {formatCurrency(activeCoupon.amount_off)} OFF
                </span>
              )}
            </>
          )}{' '}
          with code{' '}
          {isMobile() ? (
            <button
              type="button"
              className="font-semibold text-brand-secondary underline"
              onClick={() => {
                if (!userDoc) {
                  router.push('/login');
                }

                applyCoupon();
              }}
            >
              {activeCoupon.code}
            </button>
          ) : (
            <span className="font-semibold text-brand-secondary">
              {activeCoupon.code}
            </span>
          )}{' '}
          at checkout
        </p>

        {!user?.isAnonymous && (
          <button
            type="button"
            className="hidden rounded-[1rem] bg-brand-secondary px-6 py-2 text-[1.5rem] font-semibold text-white sm:block"
            onClick={() => {
              if (!userDoc) {
                router.push('/login');
              }

              applyCoupon();
            }}
          >
            Apply coupon
          </button>
        )}
      </div>
    </div>
  );
}
